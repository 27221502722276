export enum OPERATION_COLOR_ENUM {
    match1 = '#00c3bb',
    haveIt1 = '#00c3bb',
    wantIt1 = '#00c3bb',
    giveYouAHand1 = '#443570',
    giveMeAHand1 = '#00c3bb',

    haveIt2 = 'rgb(68, 53, 112)',
    wantIt2 = 'rgb(0, 195, 187)',
    giveYouAHand2 = 'rgb(227, 164, 59)',
    giveMeAHand2 = 'rgb(196, 64, 112)',

    haveIt3 = 'rgb(68, 53, 112)',
    wantIt3 = 'rgb(0, 195, 187)',
    giveYouAHand3 = 'rgb(227, 164, 59)',
    giveMeAHand3 = 'rgb(196, 64, 112)',
}
