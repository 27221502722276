import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TypePublications } from '@app/arrays/type_publication.array';

import { environment } from '@environments/environment';
import { ImagePipe } from '../image/image.pipe';
import { WindowsSizeService } from '@app/services/windows-size/windows-size.service';
import { FirstLetterUpperCasePipe } from '../firstLetterUpperCase/first-letter-upper-case/first-letter-upper-case.pipe';

@Pipe({
    name: 'search',
    standalone: true,
})
export class SearchPipe implements PipeTransform {
    firstLetterUpperCasePipe: FirstLetterUpperCasePipe =
        new FirstLetterUpperCasePipe();
    titleCase: TitleCasePipe = new TitleCasePipe();

    constructor(private windowsSizeService: WindowsSizeService) {}

    transform(value: unknown, ...args: unknown[]): string {
        const imagePipe: ImagePipe = new ImagePipe(this.windowsSizeService);
        const titlecase: TitleCasePipe = new TitleCasePipe();

        const data: any = value;
        if (!data) return '';

        switch (args[0]) {
            case 'box-title':
                return data.info
                    ? this.titleCase.transform(data.info.title)
                    : '';

            case 'name-category':
                return this.firstLetterUpperCasePipe.transform(data);
            case 'image-profile-publication': {
                if (!data) return 'assets/img/icons/user.svg';

                if (data === '') return 'assets/img/icons/user.svg';

                const picture: string = data.info.picture;

                return picture.includes('https')
                    ? picture
                    : environment.URL_FILE + picture;
            }

            case 'image-category':
                {
                    if (data && data !== '')
                        return (
                            environment.CATEGORY_FILE +
                            imagePipe.transform(data)
                        );
                    return 'assets/img/noimage.svg';
                }
                break;

            case 'img':
                {
                    if (!data.picture) return 'assets/img/icons/question.svg';
                    else if (data.picture.length == 0)
                        return 'assets/img/icons/question.svg';

                    if (data.picture[0]) {
                        const picture: string = data.picture[0];
                        return picture.includes('https')
                            ? picture
                            : environment.URL_FILE + picture;
                    }

                    return 'assets/img/icons/question.svg';
                }
                break;

            case 'title':
                return data.title;
                break;

            case 'userName':
                return `${titlecase.transform(
                    data.info.firstName
                )} ${titlecase.transform(data.info.lastName)}`;
            case 'userPicture': {
                if (!data.info.picture) return 'assets/img/icons/user.svg';

                if (data.info.picture === '')
                    return 'assets/img/icons/user.svg';

                const picture: string = data.info.picture;

                return picture.includes('https')
                    ? picture
                    : environment.URL_FILE + picture;
            }

            case 'user_name':
                const { firstName, lastName } = data;
                return this.titleCase.transform(`${firstName} ${lastName}`);
                break;

            case 'user_rating':
                const { numberStarts } = data.userId.info;
                return numberStarts;
                break;

            case 'operation_name':
                {
                    for (const TypePublication of TypePublications) {
                        if (TypePublication.id == data.typePublication)
                            return TypePublication.name;
                    }
                }
                break;

            case 'operation_id':
                {
                    for (const TypePublication of TypePublications) {
                        if (TypePublication.name == data)
                            return String(TypePublication.id);
                    }
                }
                break;

            case 'operation_title':
                {
                    for (const TypePublication of TypePublications) {
                        if (TypePublication.id == data.typePublication)
                            return TypePublication.title;
                    }
                }
                break;

            case 'operation_type':
                {
                    for (const TypePublication of TypePublications) {
                        if (TypePublication.id == data.typePublication)
                            return TypePublication.type;
                    }
                }
                break;

            case 'operation_icon':
                {
                    for (const TypePublication of TypePublications) {
                        if (TypePublication.id == data.typePublication)
                            return TypePublication.icon;
                    }
                }
                break;

            case 'operation_color':
                {
                    for (const TypePublication of TypePublications) {
                        if (TypePublication.id == data.typePublication)
                            return TypePublication.color;
                    }
                }
                break;
        }

        return '';
    }
}
